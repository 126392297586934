.wholeChartContainer, .subWholeChartContainer {
    width: 100%;
    height: 100%;
    background-color: var(--main-bg-color, #f5f5f5);
}

.wholeChartContainer {
    max-width: 50%;
}

.subWholeChartContainer {
    max-width: 100%;
}

.wholeChartContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#momentumCompositeChart {
    max-width: 100%;
}

#momentumCompositeChartTitle {
    margin: 0 0 0 6%;
}

#momentumTrafficChart {
    max-width: 100%;
}

#momentumTrafficChartTitle {
    margin: 0 0 0 6%;
}

/*.chartAndTitleContainer {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/

.chartTitle {
    color: #333333;
    font-size: 0.8em;
    margin: 0 0 0 12%;
    font-family: Roboto, SansSerif, serif;
    font-weight: 300;
}

.titleAndDropDownContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
}

.dropDown {
    margin-left: auto;
    margin-right: 10%;
}
