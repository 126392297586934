.navBarsContainer {
    grid-column: 1 / 11;
    grid-row: 1 / 2;
}

.momentumPageContainer {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: 10% repeat(8, minmax(0, 1fr));
    height: 140%;
    width: 100%;
}

.tableAndChartContainer {
    grid-column: 1 / 11;
    grid-row: 2 / 6;
    display: flex;
    flex-direction: row;
    margin-left: 1%;
    margin-right: 1%;
    align-items: flex-start;
    min-width: 98%;
    max-width: 98%;
    width: 98%;
}

.companyTable {
    width: 50%;
    min-width: 50%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
}

.twoChartsContainer {
    grid-column: 1 / 11;
    grid-row: 6 / 10;
    display: flex;
    flex-direction: row;
    margin-left: 1%;
    margin-right: 1%;
    align-items: flex-start;
    z-index: 1;
}



