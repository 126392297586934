.compositeMomentumNavBarsContainer {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
}

.compositeMomentumPageContainer {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    height: 100%;
    width: 100%;
}

.compositeMomentumBottomChartContainer {
    grid-column: 1 / 11;
    grid-row: 3 / 11;
    display: flex;
    flex-direction: row;
    margin-left: 1%;
    margin-right: 1%;
    align-items: flex-start;
    z-index: 1;
}



