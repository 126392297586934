:root {
  --main-bg-color: #f5f5f5;
  --amplify-primary-color: #5175c9;   /* main */
  --amplify-primary-tint: #7391d9; /* hover */
  --amplify-primary-shade: #4264b3; /* press down */

}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #0E1317; */
  background-color: var(--main-bg-color, #f5f5f5);
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, #appContainer {
  height: 100%;
  width: 100%;
}
