.competitorsNavBarsContainer {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
}

.competitorsPageContainer {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    height: 100%;
    width: 100%;
}

.competitorsTableAndChartContainer {
    grid-column: 1 / 11;
    grid-row: 3 / 10;
    display: flex;
    flex-direction: row;
    margin-left: 1%;
    margin-right: 1%;
    align-items: flex-start;
    min-width: 98%;
    max-width: 98%;
    width: 98%;
}

.competitorsCompanyTable {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin-top: 1%;
    padding-left: 5%;
    padding-right: 5%;
    overflow-y: scroll;
}

/*.competitorsBottomChartContainer {*/
/*    grid-column: 1 / 11;*/
/*    grid-row: 6 / 10;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    margin-left: 1%;*/
/*    margin-right: 1%;*/
/*    align-items: flex-start;*/
/*    z-index: 1;*/
/*}*/



