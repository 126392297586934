body {
    /*background-color: #3A4B63;*/
    min-height: 100%;
}

.signInContainer {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 10vh));
    height: 100%;
    width: 100%;
    background-color: var(--main-bg-color, #f5f5f5);
}

/* .navbar {
    grid-column: 1 / 11;
    grid-row: 1 / 2;
} */

.landingPageImg {
    grid-column: 1 / 7;
    grid-row: 1 / 11;
}

.landingPageImg img {
    height: 100%;
    width: 100%;
}

.signInSheetContainer {
    grid-column: 7 / 11;
    grid-row: 1 / 11;
    /* background-color: white; */
    padding: 0;
    margin-right: 10%;
    border-radius: 20px;
}

.loggingInText {
    /* color: rgb(255, 30, 0); */
    margin-top: 25%;
    margin-left: 5%;
    position: absolute;
}

.signInSheet {
    height: 100%;
    width: 100%;
}


